import './tutorialtile.css'

function Tutorialtile({ tutorial }) {
    return (
        <a className='tutorialtile-container' href={tutorial.link} target="_blank" style={{ textDecoration: "none" }}>
            <div className="tutorial-img">
                <img className="tutorial-img-src" src={tutorial.img} />
            </div>
            <div className="link-logo">
                <div className="logo">
                    <img className="logo-img" src={tutorial.logo} />
                </div>
                <div className="tutorial-source">
                    {tutorial.source}
                </div>
            </div>
            <div className="tutorial-title">
                {tutorial.title}
            </div>
            <div className="tutorial-desc">
                {tutorial.description}
            </div>
        </a>
    )
}

export default Tutorialtile