import './jobtile.css'
import Keyword from '../keyword/Keyword'
import { Link } from 'react-router-dom'
import { format } from 'timeago.js'


function Jobtile({ job }) {


    const keywords = job.keywords


    return (
        <div className='jobtile-container'>
            <div className="jobtile-top">
                <div className="company-logo">
                    <img className="company-logo-img" src={job.logourl} />
                </div>
                <div className="position-wrapper">
                    <Link className="position-title" to={"/job/" + job._id} style={{ textDecoration: 'none', color: 'black' }} >
                        {job.title}
                    </Link>
                    <div className="position-employer">
                        {job.employer}
                    </div>
                </div>
                {/* <div className="date-posted">
                    {format(job.createdAt)}
                </div> */}
            </div>
            <div className="jobtile-center">
                <div className="category-info">
                    <div className="job-domain">
                        {job.primaryRole}
                    </div>
                    <div className="dot"></div>
                    <div className="job-domain">
                        {job.positionType}
                    </div>
                    <div className="dot"></div>
                    <div className="job-domain">
                        {job.location}
                    </div>
                    <div className="dot"></div>
                    <div className="job-domain">
                        {
                            job.compensation ? <div className='job-domain'> ${job.compensation?.min}k - ${job.compensation?.max}k</div> : <div className="job-domain">N/A</div>
                        }
                    </div>
                </div>
                <div className="extra">
                    <div className="job-keywords">
                        {
                            keywords?.slice(0, 6).map((p) => (<Keyword data={p} />))
                        }
                    </div>
                    <Link to={"/job/" + job._id} className="apply-btn" style={{ textDecoration: 'none' }}>
                        Apply
                    </Link>
                </div>
            </div>

        </div>
    )
}

export default Jobtile