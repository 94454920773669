import './jobboard.css'
import Alert from '@mui/material/Alert';
import Jobtile from '../jobtile/Jobtile'
import { useState, useEffect } from 'react'
import { MdExpandMore } from "react-icons/md";
import axios from 'axios'



function Jobboard({ domain, positionType, locationType, isFilter }) {

    const [jobs, setJobs] = useState([])
    const [moreJobs, setMoreJobs] = useState(false)

    useEffect(() => {
        const fetchJobs = async () => {
            const res = await axios.get("https://drifly-api.herokuapp.com/api/job")
            setJobs(res.data)
            console.log(res.data)
        }
        fetchJobs()
    }, [])

    const handleClick = () => {
        setMoreJobs(true)
    }



    return (
        <div className='jobboard-wrapper'>
            <div className="left">

            </div>
            <div className="center">
                <div className="center-top">
                    {/* <Alert variant="outlined" className="alert" severity="info"><div className='alert-txt'>version2: Adding Searchbar and Filters</div></Alert> */}
                    {/* <div className="searchbar">
                        <Select placeholder="Job Title, Company" components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }} />
                    </div> */}
                </div>
                <div className="center-jobs">

                    {
                        moreJobs ? jobs.map((p) => (<Jobtile key={p._id} job={p} />)) : jobs.slice(0, 3).map((p) => (<Jobtile key={p._id} job={p} />))
                    }
                    {
                        moreJobs ? <> </> : <button className="more-job-btn" onClick={handleClick}> <MdExpandMore /></button>

                    }

                </div>
            </div>
            <div className="right">

            </div>
        </div>
    )
}

export default Jobboard