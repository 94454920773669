import styled from 'styled-components'
import {FaTimes} from 'react-icons/fa'
import { Link as ScrollLink, animateScroll as Scroll } from "react-scroll";
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom'





export const SidebarContainer = styled.div`
    position:fixed ;
    z-index: 999;
    width: 100%;
    height:100% ;
    background: #0d0d0d;
    display:grid ;
    align-items:center ;
    top:0;
    transition:0.3s ease-in-out;
    opacity:${({isOpen}) => (isOpen ? '97%' :'0')} ;
    top:${({isOpen}) => (isOpen ? '0' : '-100%')};
    
`
export const CloseIcon = styled(FaTimes)`
    color:#fff;
`

export const Icon = styled.div`
    position:absolute ;
    top:1.2rem;
    right:1.5rem;
    background:transparent;
    font-size:2rem;
    cursor: pointer;
    outline:none;
`
export const SidebarWrapper = styled.div`
        color:#fff;
`
export const SidebarMenu = styled.div`
    
`
export const SidebarLinkS = styled(HashLink)`
    display: flex;
    align-items:center ;
    justify-content:center ;
    font-size:1.5rem ;
    text-decoration:none ;
    margin-bottom:15px ;
    transition: 0.2s ease-in-out;
    color:#fff;
    cursor:pointer;

    &:hover{
        color: #9b3adc;
        transition: 0.2s ease-in-out
    }
`

export const SidebarLink = styled(Link)`
    display: flex;
    align-items:center ;
    justify-content:center ;
    font-size:1.5rem ;
    text-decoration:none ;
    margin-bottom: 15px ;
    transition: 0.2s ease-in-out;
    color:#fff;
    cursor:pointer;

    &:hover{
        color:#9b3adc;
        transition: 0.2s ease-in-out
    }
`
export const SidebarLinkA = styled.a`
    display: flex;
    align-items:center ;
    justify-content:center ;
    font-size:1.5rem ;
    text-decoration:none ;
    margin-bottom:20px ;
    transition: 0.2s ease-in-out;
    color:#fff;
    cursor:pointer;

    &:hover{
        color:#5a1bb1;
        transition: 0.2s ease-in-out
    }
`


export const SideBtnWrap = styled.div`
    margin-top: 60px;
    display: flex;
    justify-content:center ;
    
`
export const SidebarRoute =  styled.a`
    border-radius: 50px ;
    background: white;
    white-space: nowrap;
    color:black;
    padding:16px 32px;
    font-size:16px;
    outline:none;
    border:none;
    cursor:pointer;
    transition: all 0.2s ease-in-out ;
    text-decoration: none ;

    &:hover{
        color:white;
        background: black;
        border:2px solid;
        transition: 0.3s ease-in-out
    }
`
