import './App.css';
import Home from './pages/home/Home';
import Job from './pages/job/Job';
import Learn from './pages/learn/Learn'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Navigate
} from "react-router-dom";

function App() {
  return (
  <div className='App'>
   <Router>
     <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/job/:jobid" element={<Job/>}/>
      <Route path="/learn" element={<Learn/>}/>
      {/* <Route path="/blog"component={()=>{
        window.location.replace ('https://www.notion.so/524b0bbfc0ca408e9076a6d3cd43abbe?v=dec6d23ecc3f4be4b498c81f1be17211')
        return null
      }}/> */}

      
    </Routes>
   </Router>
  </div>
  );
}

export default App;
