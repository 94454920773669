import Topbar2 from '../../components/topbar-2/Topbar2'
import './job.css'
import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import axios from 'axios'
import Keyword from '../../components/keyword/Keyword'
import Description from '../../components/description/Description';
import Tutorialtile from '../../components/tutorialtile/Tutorialtile'
import MadeWithLove from 'react-made-with-love'
import Sidebar from '../../components/sidebar/Sidebar';

function Job() {

    const params = useParams()
    const [job, setJob] = useState([])
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        const fetchJob = async () => {
            const res = await axios.get("https://drifly-api.herokuapp.com/api/job/" + params.jobid)
            setJob(res.data)
        }
        fetchJob();
    }, [])



    return (
        <div>
            <div className="topbar">
                <Sidebar isOpen={isOpen} toggle={toggle} />
                <Topbar2 toggle={toggle} />
            </div>
            <div className="job-content">
                <div className="content-col-1">
                    <div className="job-title">
                        {job.title}
                    </div>
                    <div className="logo-employer">
                        <div className="job-logo">
                            <img className="job-logo-img" src={job.logourl}></img>
                        </div>
                        <div className="job-employer">
                            {job.employer}
                        </div>
                    </div>
                    <div className="job-info">
                        <div className="job-info-txt">
                            {job.primaryRole}
                        </div>
                        <div className="dot-sep"></div>
                        <div className="job-info-txt">
                            {job.positionType}
                        </div>
                        <div className="dot-sep"></div>
                        <div className="job-info-txt">
                            {job.location}
                        </div>
                        <div className="dot-sep"></div>
                        <div className="job-info-txt">
                            {
                                job.compensation ? <div className='job-info-txt'> ${job.compensation?.min}k - ${job.compensation?.max}k</div> : <div className="job-info-txt">N/A</div>
                            }
                        </div>
                    </div>
                    <div className="job-keywords">
                        {job.keywords?.map((p) => <Keyword className="job-keywords-txt" data={p} />)}
                    </div>
                    <div >
                        <form action={job.applicationLink} target="_blank">
                            <input className="job-apply-btn" type="submit" value="Apply Now" />
                        </form>

                    </div>
                    <div className="job-desc">
                        <Description />
                    </div>

                    <div >
                        <form action={job.applicationLink} target="_blank">
                            <input className="job-apply-btn" type="submit" value="Apply Now" />
                        </form>

                    </div>
                    <div className="catchy-title">
                        You might not wanna miss this opportunity!
                    </div>
                </div>
                <div className="content-col-2">

                </div>
            </div>
           
        </div>
    )
}

export default Job
