import './keyword.css'

function Keyword({ data }) {
 
    return (
        <div className='keyword'>
            {data}
        </div>
    )
}

export default Keyword