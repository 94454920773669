import './postjob.css'

function Postjob() {
    return (
        <div className='postjob-container'>
            <div className="postjob-content">
                <img className="postjob-img" src={require('../../assets/fist-bump-gesture.png')}></img>
                <div className="postjob-title">
                    Hire Web3 Talent
                </div>
                <div className="postjob-desc">
                    Post a job and hire young professionals from our community ready to disrupt the new web from day one.
                </div>

                <a className="postjob-btn" href="https://forms.gle/kJAzrFxs5RfphUKz9" target="_blank" style={{ textDecoration: "none" }} >
                    Post Jobs
                </a>


            </div>


        </div>

    )
}

export default Postjob