import './discord.css'

function Discord() {
    return (
        <div className='discord-container'>
            <div className="discord-content">
                <img className="discord-img" src={require('../../assets/discord.png')} />
                <div className="discord-title">
                    Connect, Share, Build
                </div>
                <div className="discord-desc">
                    Join our growing Discord community of Web3 Founders, Developers, and Learners.
                </div>
                <a className="discord-btn" href="https://discord.gg/dJTYS5uv" target="_blank" style={{ textDecoration: "none" }} >
                    Join Discord
                </a>
            </div>

        </div>
    )
}

export default Discord