import React from 'react'
import { FaBars } from 'react-icons/fa';
import { Link } from 'react-router-dom'
import { Link as ScrollLink, animateScroll as Scroll } from "react-scroll";
import { HashLink } from 'react-router-hash-link';
import './topbar.css'


function Topbar({ toggle }) {
    return (
        <>
            <div className="nav">
                <div className="navLeft">
                    <Link to="/" className="logo" style={{ textDecoration: "none" }}>
                        <img className="logoImg" src={require('../../assets/logo.png')} />
                        <div className="logoText">drifly</div>
                    </Link>
                </div>

                <div className='menuBar'>
                    <FaBars onClick={toggle} className='hamburger' />
                </div>
                <div className="navMenu">
                    <ScrollLink to="section2" spy={true} smooth={true} offset={-70} duration={500} className="navLink">
                        Jobs
                    </ScrollLink>
                    <Link to="/learn" className="navLink" style={{ textDecoration: "none" }}>
                        Learn
                    </Link>
                    <a href="https://www.notion.so/524b0bbfc0ca408e9076a6d3cd43abbe?v=dec6d23ecc3f4be4b498c81f1be17211" target="_blank" className="navLink" style={{ textDecoration: "none" }}>
                        Blog
                    </a>
                    <a href="https://discord.gg/dJTYS5uv" target="_blank" className="navLink" style={{ textDecoration: "none" }}>
                        Discord
                    </a>

                </div>
                <div className="navRight">
                    <a href="https://forms.gle/kJAzrFxs5RfphUKz9" target="_blank" style={{ textDecoration: "none" }} className="navRightButton">
                        Post Jobs
                    </a>

                </div>
            </div>
        </>
    )
}

export default Topbar