import './hero.css'
import { Link as ScrollLink, animateScroll as Scroll } from "react-scroll";



function Hero() {
    return (
        <div className="hero-wrapper">
            <div className="hero-content">
                <div className='hero-title'>
                    Learn, Build, and Explore
                    <br />the future of web3.0
                </div>
                <div className='hero-desc'>
                    Platform to find the web3 jobs, access the learning resources,<br /> and explore the future of web
                </div>
                <div className='hero-btn'>
                    <ScrollLink className="hero-btn-txt" to="section2" spy={true} smooth={true} offset={-75} duration={500} >
                        Find a job
                    </ScrollLink>
                </div>


            </div>
        </div >
    )
}

export default Hero