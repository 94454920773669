import './learncard.css'
import { Link } from 'react-router-dom'

function Learncard() {
    return (
        <div className='learncard-container'>
            <div className="learncard-content">
                <img className="learncard-img" src={require('../../assets/youtube.png')} />
                <div className="learncard-title">
                    Web3 Tutorials
                </div>
                <div className="learncard-desc">
                    Curated list of resources for you to learn new technology, and get started with building the future of web
                </div>
                <Link to="/learn" className="learncard-btn" href="https://learncard.gg/dJTYS5uv" style={{ textDecoration: "none" }} >
                    Start Learning
                </Link>
            </div>

        </div>
    )
}

export default Learncard