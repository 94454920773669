import './learn.css'
import Topbar2 from '../../components/topbar-2/Topbar2'
import Tutorialtile from '../../components/tutorialtile/Tutorialtile'
import Sidebar from '../../components/sidebar/Sidebar'
import MadeWithLove from 'react-made-with-love'

import { useState, useEffect } from 'react'
import axios from 'axios'

function Learn() {


    const [tutorials, setTutorials] = useState([])
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        const fetchTutorials = async () => {
            const res = await axios.get("https://drifly-api.herokuapp.com/api/tutorial")
            setTutorials(res.data)
            console.log(res.data)
        }
        fetchTutorials()
    }, [])

    return (
        <div>
            <div className="topbar">
                <Sidebar isOpen={isOpen} toggle={toggle} />
                <Topbar2 toggle={toggle} />
            </div>
            <div className="learn-desc">
                <div className="learn-title">Web3 Tutorials</div>
                <div className="learn-text">Curated list of resources for you to learn new technology, and get started with building the future of web </div>
            </div>
            <div className="learn-content">
                {
                    tutorials.map((p) => (<Tutorialtile key={p._id} tutorial={p} />))
                }
            </div>
            <div className="footer">

                <MadeWithLove by="Drifly Team" emoji link="https://www.linkedin.com/in/deependrasthakur/" />
            </div>

        </div>
    )
}

export default Learn