import './description.css'
import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import axios from 'axios'

function Description() {
    const params = useParams()
    const [job, setJob] = useState([])
    const [company, setCompany] = useState("")
    const [description, setDescription] = useState("")
    const [responsibilities, setResponsibilites] = useState("")
    const [req, setReq] = useState("")
    const [pref, setPref] = useState("")
    const [comp, setComp] = useState("")
    const [app, setApp] = useState("")




    useEffect(() => {
        const fetchJob = async () => {
            const res = await axios.get("https://drifly-api.herokuapp.com/api/job/" + params.jobid)
            setJob(res.data)
            setCompany(res.data.detail.aboutCompany)
            setDescription(res.data.detail.description)
            setResponsibilites(res.data.detail.responsibilities)
            setReq(res.data.detail.requirements)
            setPref(res.data.detail.prefRequirements)
            setComp(res.data.detail.compensationDetail)
            setApp(res.data.detail.applicationProcess)
        }
        fetchJob();
    }, [])
    return (
        <div className='description-content'>
            <div className="aboutCompany">
                <div className="title">
                    About {job.employer}
                </div>
                <div className='content'>
                    {company}
                </div>
            </div>
            <div className="description">
                <div className="title">
                    Job Description
                </div>
                <div className='content'>
                    {description}
                </div>
            </div>
            <div className="Requirements">
                <div className="title">
                    Responsibilites
                </div>
                <div className='content'>
                    {responsibilities}
                </div>
            </div>
            <div className="Requirements">
                <div className="title">
                    Requirements
                </div>
                <div className='content'>
                    {req}
                </div>
            </div>
            <div className="pref-requirements">
                <div className="title">
                    Preferred requirements
                </div>
                <div className='content'>
                    {pref}
                </div>
            </div>
            <div className="compensation-detail">
                <div className="title">
                    Benefits
                </div>
                <div className='content'>
                    {comp}
                </div>
            </div>
            <div className="application-process">
                <div className="title">
                    Application Process
                </div>
                <div className='content'>
                    {app}
                </div>
            </div>

        </div>
    )
}

export default Description