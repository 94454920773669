import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper,SidebarMenu, SidebarLink, SideBtnWrap, SidebarRoute, SidebarLinkS, SidebarLinkA} from './SidebarElements'

const Sidebar = ({isOpen, toggle}) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon/>
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SidebarLinkS to="/#section2" spy={true} smooth={true} offset={-70} duration={500} onClick={toggle}>
                    Jobs
                </SidebarLinkS>
                <SidebarLink to="/learn">
                    Learn
                </SidebarLink>
                <SidebarLinkA onClick={toggle} href="https://www.notion.so/524b0bbfc0ca408e9076a6d3cd43abbe?v=dec6d23ecc3f4be4b498c81f1be17211" target="_blank">
                    Blog
                </SidebarLinkA>
                <SidebarLinkA onClick={toggle} href="https://discord.gg/dJTYS5uv" target="_blank">
                    Discord
                </SidebarLinkA>
               
            </SidebarMenu>
            <SideBtnWrap>
            <SidebarRoute onClick={toggle} href="https://forms.gle/kJAzrFxs5RfphUKz9" target="_blank" >
                    Post Jobs
            </SidebarRoute>
            </SideBtnWrap>
    
        </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar
