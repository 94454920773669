import './home.css'
import Topbar from '../../components/topbar/Topbar'
import Hero from '../../components/hero/Hero'
import Jobboard from '../../components/jobboard/Jobboard'
import MadeWithLove from 'react-made-with-love'
import { useState } from 'react'
import Discord from '../../components/discord/Discord'
import Sidebar from '../../components/sidebar/Sidebar'
import Postjob from '../../components/postjob/Postjob'
import Learncard from '../../components/learncard/Learncard'


function Home() {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div className='home'>
            <div className="navbar">
                {/* <Navigation /> */}
                <Sidebar isOpen={isOpen} toggle={toggle} />
                <Topbar toggle={toggle} />
            </div>
            <section className="section-1">

                <div className="hero">
                    <Hero />
                </div>
            </section>
            <section className='section-2' id="section2">
                <div className="section2-title">
                    Web3 Jobs
                </div>
                <Jobboard />
            </section>

            <section className='section-3' id="section3">
                <Learncard />
                <Postjob />
            </section>
            <section className="section-4">

                <Discord />
            </section>
            <div className="footer">
                <MadeWithLove by="Drifly Team" emoji link="https://www.linkedin.com/in/deependrasthakur/" />
            </div>
        </div>
    )
}

export default Home